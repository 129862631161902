<script setup>
const props = defineProps({
  noOuterPaddings: Boolean,
  bgColor: {
    type: String,
    default: () => ''
  }
})
const backend = useBackend();

const {data: brands} = await useAsyncData(() => new Promise(done => {
  backend.getBrands().then(result => {
    done(result.data);
  })
}))


</script>

<template>
  <div 
    class="w-full"
  >
    <div class="w-full flex flex-col md:flex-row"
      :class="{'xl:px-20':!noOuterPaddings}"
    >
      <div class="w-full md:w-32 shrink-0 pb-8 sm:pb-0">
        <div class="pt-3 pb-6 text-center text-xl">Na skróty:</div>
      </div>
      <div class="grid grid-cols-5 md:grid-cols-7 lg:grid-cols-11 xl:grid-cols-19 w-full grow justify-center">
        <template v-for="brand in brands">
          <ButtonBrand :name=brand.name
                       :alt="`Leasing ${brand.name}`"
                       :icon=brand.svg
                       :href="{name: 'brand', params: {brand: brand.name.toLowerDashed()}}"
                       small
          />
        </template>
      </div>
    </div>
  </div>
</template>
